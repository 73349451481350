import { render, staticRenderFns } from "./_asyent-services.vue?vue&type=template&id=d4428c5a&scoped=true&"
import script from "./_asyent-services.vue?vue&type=script&lang=js&"
export * from "./_asyent-services.vue?vue&type=script&lang=js&"
import style0 from "./_asyent-services.vue?vue&type=style&index=0&id=d4428c5a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4428c5a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VIcon,VSheet,VSkeletonLoader,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d4428c5a')) {
      api.createRecord('d4428c5a', component.options)
    } else {
      api.reload('d4428c5a', component.options)
    }
    module.hot.accept("./_asyent-services.vue?vue&type=template&id=d4428c5a&scoped=true&", function () {
      api.rerender('d4428c5a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/admin/pages/discovery/_asyent-services.vue"
export default component.exports